import React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Link, Route, Routes, useLocation } from 'react-router-dom';

import App from './App';
import { SessionProvider } from './hooks/SessionProvider';
// import { CookiesProvider } from 'react-cookie';
import CookieConsent from 'react-cookie-consent';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import './sass/index.scss';



const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <CookiesProvider defaultSetOptions={{ path: '/' }}>
//         <App />
//       </CookiesProvider>
//     </BrowserRouter>
//   </React.StrictMode>,
// );

root.render(
  <React.StrictMode>
    <BrowserRouter>
        <ConsentContainer />
    </BrowserRouter>
  </React.StrictMode>,
);

function ConsentContainer() {
  

  const [hasConsented, setHasConsented] = useState(false);
  const [showConsent, setShowConsent] = useState('show');
  const { pathname } = useLocation();

  function insertScript(scriptContent, targetElement = document.body) {
    const script = document.createElement('script');
    script.textContent = scriptContent;
    targetElement.appendChild(script);
  }

  const scriptContent = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '9418245814861648');
      fbq('track', 'PageView');
  `;

  useEffect(() => {
    const storedConsent = localStorage.getItem('cookieConsent'); 
    if (storedConsent) {
      handleConsent();
    }
  }, []);

  useEffect(() => {
    if (hasConsented) {
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-1QHK0NSE8C');
      gtag('event', 'page_view', {
        'page_path': pathname, // If using React Router
        'page_title': pathname
      });

      // window.fbq = window.fbq || [];

      // function fbq(){window.fbq.push(arguments);}
      // // fbq('init', '9418245814861648'); 
      // fbq('init', '9418245814861648');
      // fbq('track', 'PageView');
    }
  }, [hasConsented]);

  useEffect(() => {
    console.log("show location.pathname: ",pathname)
    console.log("show consent",showConsent)
  }, [showConsent]);

  async function handleConsent() {
    
    localStorage.setItem('cookieConsent', 'true'); 
  
    // Initialize and send pageview to Google Analytics
    const scriptGoogle = document.createElement('script');
    scriptGoogle.src = `https://www.googletagmanager.com/gtag/js?id=G-1QHK0NSE8C`; 
    scriptGoogle.async = true;
    document.head.appendChild(scriptGoogle);

    // Initialize Hyroz
    // const scriptHyros = document.createElement('script');
    // scriptHyros.src = "https://careconnect.mycarity.us/v1/lst/universal-script?ph=1f762706fe8adb7d1d9c877436701adeabe93f1c51107fa8ca04dd4523c2bd68&tag=!clicked&spa=true&ref_url=" + encodeURI(document.URL); 
    // scriptHyros.async = true;
    // document.head.appendChild(scriptHyros);
  
    // Initialize Meta Pixel
    const pixelScript = document.createElement('script');
    pixelScript.src = `https://connect.facebook.net/en_US/fbevents.js`; 
    pixelScript.async = true;
    pixelScript.defer = true;
    document.head.appendChild(pixelScript);

    insertScript(scriptContent);
    setHasConsented(true);
  }



  return <>
    <App />
    {
      (hasConsented)
      ? null
      : <CookieConsent
        visible={showConsent}
        flipButtons
          location="bottom" 
          buttonText="Accept"
          declineButtonText="Decline"
          enableDeclineButton={true}
          onDecline={()=>setShowConsent('hidden')}
          cookieName="CookieConsent"
          style={{ background: '#2B373B' }} 
          buttonStyle={{ color: '#FFFFFF', fontSize: '13px' }}
          expires={150} 
          onAccept={handleConsent}
        >
          This website uses cookies to enhance your browsing experience and for analytics purposes. By clicking 'Accept' or continuing to use this site, you consent to the use of cookies as described in our <a href='/policy' target='_blank'>Privacy Policy</a>
        </CookieConsent>
    }
    
  </>

}
