import React from 'react';
import { useState, useEffect, useRef} from 'react';
import {checkEmailFormat, promiseWait} from '../../helpers/Utilities'
import settings from '../../setttings.json';
import { Route, Routes, useLocation } from 'react-router-dom';

export default function FormModal() {
  // const { sessionState, setInitialized } = useSession();
  // const [cookies, setCookie] = useCookies(['subscribed']);
  const [toggle, setToggle] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { pathname } = useLocation();

   useEffect(() => {
      const newsletterSubscribed = localStorage.getItem('newsletterSubscribed'); 
      
      if (newsletterSubscribed) {
        console.log("is subscibred close:", pathname, newsletterSubscribed);
        promiseWait(1000).then(()=>setToggle(false));
        
      } else {
        console.log("is subscibred show:", pathname, newsletterSubscribed);
        promiseWait(1000).then(()=>setToggle(true));
      }
    }, [pathname]);

  // useEffect(() => { 
  //   console.log(`cookie: ${cookies.subscribed}`);
  //   if(!cookies.subscribed || (cookies && cookies.subscribed !== 1)){
  //     promiseWait(1000).then(()=>setToggle(true));
  //   }
  // }, []);
 
  const handelClose = () => {
    setToggle(!toggle);
  }

  const handleSubmit = (event) => {

    setIsSubmitting(true);
    console.log(`name: ${name}, email: ${email}`);

    // Check Name has value
    if(name.trim().length < 3){
      alert(`Please enter your name`);
      setIsSubmitting(false);
      return;
    }
    
    // Check if valid Email
    if(!checkEmailFormat(email)){
      alert(`Please enter valid email`);
      setIsSubmitting(false);
      return;
    }

    const frmBody = {
      email : email.trim(),
      name : name.trim(),
    }
   
    const options = {
      crossDomain: true,
      dataType: "json",
      contentType: false,
      method: 'POST',
      body: JSON.stringify(frmBody),
    }
    // promiseWait(3000).then(()=>{
    //   setCookie('subscribed', 1);
    //   setIsSubmitting(false);
    // });
    promiseWait(1000).then(()=>{
      fetch(settings.subscribe_email_url, options)
      .then((response) => response.json())
      .then((data) => {
        console.log("RESPONSE:", data);
        alert(`Thank you for subscribing! We're excited to have you join us. Expect exclusive updates, special offers, and more!`);
        localStorage.setItem('newsletterSubscribed', 'true'); 
        setIsSubmitting(false);
        setToggle(!toggle);
      })
      .catch((error) => {
        console.error("RESPONSE:", error);
        alert("We're experiencing a temporary technical issue. Please try submitting your message again later. We apologize for any inconvenience.");
        setIsSubmitting(false);
        setToggle(!toggle);
      });
    });

  }

  const SubmitBtn = () =>{
    return <>
    {
      (isSubmitting)
      ? <button className="cs_btn cs_style_1" id="btnSubmitting">
          <span>Submitting...Please wait.</span>
        </button>
      : <button className="cs_btn cs_style_1" onClick={handleSubmit} id="btnSubmit">
          <span>Submit</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
    }
      
      
    </>
  }

  return (
    <>
    <div className={toggle ? 'cs_form_popup active' : 'cs_form_popup'}>
     <div className="cs_form_popup_overlay" />
        <div className="cs_form_popup_content">
        <div className='cs_form_popup_container'>

          <div className="cs_form_popup_layer" />
              <div className="embed-responsive embed-responsive-16by9" style={{backgroundColor:'white'}}>
                <iframe
                  // className="embed-responsive-item"
                  // width={'100%'}
                  // height={'100%'}
                  style={{position:'absolute', top:0, left:0, padding:0}}
                  src={"https://c9746980.sibforms.com/serve/MUIFADobPQFUsb9XrSqeejNx607ZFN5lMVqIHzFJfIDFCO8Zw4MKymxZIY8FwnOqUb7uUOcQody6fbHnH-21B3LMIBhzpSIjU_GJbaaDx6PAdjrxSfOZ6tz_YuePgVdQT8tcntjieiNOfPENtIxtEJYv8bGUZcPgZqP8dO65hn2IeUvamSVYzqZGfuWCqJyRiGwYto8eQweD1Fmh"}
                  title="video modal"
                />
                 <div className="cs_form_popup_close" onClick={handelClose} />

              </div>
            </div>
        </div>
        </div>
    </>
  )
}
