import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import ContactForm from '../ContactForm';
import Section from '../Section';
import ContactInfoSection from '../Section/ContactInfoSection';
import { pageTitle } from '../../helpers/PageTitle';
import FormModal from '../FormModal';

export default function Contact() {
  pageTitle('Contact');
  // Trigger this when the Contact page is displayed
  // window.gtag('event', 'page_view', {
  //   page_title: 'Contact Page',
  //   page_path: '/contact',
  // });
  return (
    <>
    <FormModal/>
      <BannerSectionStyle5
        bgUrl="/images/contact/banner_bg.svg"
        imgUrl="/images/contact/banner_img.png"
        title="Contact Us"
        subTitle="Use this form to submit inquiries related to your MyCarity account. Complete the form, and our team will respond as soon as possible. 
        For more information about your rights and our privacy practices, please review our <br> <a href='/policy' target='_blank'><b>Privacy Policy</b></a> and <a href='/terms' target='_blank'><b>Terms Of Use</b></a>"
      />
      <div className="container cs_mt_minus_110" style={{marginTop:-20}}>
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <ContactForm />
          </div>
        </div>
      </div>
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection sectionTitle="Contact Us Here" />
      </Section>
    </>
  );
}
