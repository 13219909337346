import React from 'react';
import { Link } from 'react-router-dom';
import Section from '../Section';
import Breadcrumb from '../Breadcrumb';
import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import { Icon } from '@iconify/react';
import Spacing from '../Spacing';
import Post from '../Post';
import Sidebar from '../Sidebar';
import AuthorWidget from '../Widget/AuthorWidget';
import CommentsWidget from '../Widget/CommentsWidget';
import ReplyWidget from '../Widget/ReplyWidget';
import { pageTitle } from '../../helpers/PageTitle';
import FormModal from '../FormModal';
const tags = [
  { tag: 'Emergency', href: '/blog/blog-details' },
  { tag: 'Pediatric', href: '/blog/blog-details' },
  { tag: 'Cardiology', href: '/blog/blog-details' },
  { tag: 'Psychiatry', href: '/blog/blog-details' },
  { tag: 'Others', href: '/blog/blog-details' },
];
const relatedBlog = [
  {
    title:
      'About Us and This Notice',
    thumbUrl: '/images/blog/post_1.jpeg',
    date: 'May 27, 2024',
    btnText: 'Learn More',
    href: '/blog/blog-details',
  },
  {
    title: `A Parent's Guide to Childhood Vaccinations: What You Need to Know`,
    thumbUrl: '/images/blog/post_2.jpeg',
    date: 'March 11',
    btnText: 'Learn More',
    href: '/blog/blog-details',
  },
  {
    title: 'Preventing Heart Disease: Tips for a Heart-Healthy Lifestyle',
    thumbUrl: '/images/blog/post_3.jpeg',
    date: 'March 9',
    btnText: 'Learn More',
    href: '/blog/blog-details',
  },
];

export default function PrivacyNotice() {
  pageTitle('Privacy Policy');
   // Trigger this when the Privacy Policy page is displayed
  //  window.gtag('event', 'page_view', {
  //   page_title: 'Privacy Policy Page',
  //   page_path: '/policy',
  // });
  return (
    <>
    <FormModal/>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title="Privacy Notice" />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            {/* <div className="cs_blog_details_tags">
              {tags.map((item, index) => (
                <Link key={index} to={item.href}>
                  {item.tag}
                </Link>
              ))}
            </div> */}
            <div className="cs_blog_details_date">
            June 02, 2024 | LAST UPDATED
            </div>
          </div>
          {/* <div className="cs_social_links_wrap">
            <h2>Share:</h2>
            <div className="cs_social_links">
              <Link to="/">
                <Icon icon="fa-brands:facebook-f" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:linkedin-in" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:twitter" />
              </Link>
            </div>
          </div> */}
        </div>
        {/* <Spacing md="55" /> */}
        {/* <img
          src="/images/blog/blog_details_1.jpeg"
          alt="Blog Details"
          className="w-100 cs_radius_20"
        /> */}
        {/* <Spacing md="90" lg="50" /> */}
        <div className="row">
          
            <div className="cs_blog_details">
              <h2>Welcome, and thank you for visiting our Website or using our Services!</h2>
              
              {/* <p>
              When you visit our Website or use our Services, you provide and entrust us with certain personal information (which we refer to herein as “your information”). We understand that your information is important to you, and we take your privacy and data protection concerns seriously. This Privacy Notice (“Notice”) is meant to help you understand how we collect, use, and share your information and how you can exercise the choices and rights you have to your information.
               <br />
                <br />
                When this Notice mentions “MyCarity,” “we,” “us,” or “our” it refers to MyCarity wholly owned subsidiary that is responsible for your information under this Notice.
              </p> */}
              <ol>
                <li>
                  <b>About Us and This Notice</b>
                  <br />
                  When you visit our Website or use our Services, you provide and entrust us with certain personal information (which we refer to herein as “your information”). We understand that your information is important to you, and we take your privacy and data protection concerns seriously. This Privacy Notice (“Notice”) is meant to help you understand how we collect, use, and share your information and how you can exercise the choices and rights you have to your information.
                  <br />
                  <br />
                  When this Notice mentions “MyCarity,” “we,” “us,” or “our” it refers to MyCarity wholly owned subsidiary that is responsible for your information under this Notice.
                </li>
                <li>
                  <b>Applicability and Scope of This Notice</b> <br />
                  This Notice is applicable to all MyCarity websites that link to this Notice (collectively, the “Websites”), MyCarity’s platform and services, including the associated MyCarity mobile applications (collectively, the “Services”), and any other interactions (e.g. customer service inquiries, webinars, conference) you may have with MyCarity.
                  <br /><br />
                  This Notice applies only to your personal information, which is information that is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, to an individual or household. For the purposes of this Notice, your information does not include aggregate or de-identified information maintained in any form that does not identify you or cannot be reasonably linked to an individual. Additionally, MyCarity provides certain Services on behalf of our customers who operate within the caregiving industry, such as caregivers, clients or the one referred to as the care seekers, (referred to as our “Business Customer(s)”). To understand how we process your information on behalf of a Business Customer, please refer to the privacy notice of the Business Customer with whom you have a relationship for information on how they engage us, and how they collect, use, and share your information.
                </li>
                <li>
                  <b>Information We Collect</b>
                  <br />
                  When you use or interact with MyCarity’s Websites and or Services, we may collect information that you provide, usage information, and information about your device. We may also collect information about you (and combine it with other information we have about you) from other sources who collect your information on our behalf (e.g., service providers). See below for examples of information we collect or receive. We may provide supplemental information about our data collection or privacy practices in disclosures about specific Services or circumstances, or in some cases, at the time we collect personal information from you.                  
                  <br /><br />
                  3.1. Information You Provide Us   
                  <br/>
                  <br/>
                  <b>To use our Services.</b> 
                  <br />          
                  When you use our Applications, create an account or profile in one of our Services, or use certain Services, such as background screening, you may provide personal identifiers (e.g., your first name, last name, email address, date of birth, phone number, postal address, IP address, account name), customer records (e.g., username, password and password use as digital signature, payment information, email address), other sensitive personal information (e.g., social security number, driver’s license number, passport number, tax identification number, government issued ID, national ID card), and other similar personal information.  
                  <br/>
                  <br/>
                  <b>To provide payment services.</b> 
                  <br />          
                  We do not collect or store sensitive financial data ourselves. In order to facilitate payments on our platform, or to make and receive payments on our platform, you may be required by our third-party service partners, such as Apple Pay, Google Pay, and Stripe, to provide financial and other sensitive personal information, such as your bank account number, online banking login credentials, and credit card information. These payment processors may also capture additional information when you make payments, such as card type, transaction type, and payee information.                
                  <br/>
                  <br/>
                  <b>To comply with our corporate policies and regulatory obligations.</b> 
                  <br />          
                  In connection with (a) maintaining reasonable data protection safeguards, including the security of our services, (b) fulfilling “know your customer” processes, required for certain payment services, and (c) complying with other various regulatory requirements related to our Services, you may be required to provide us with personal identifiers (e.g., first name, last name, email address, date of birth, phone number, IP address, account name), other sensitive personal information (e.g., social security number, tax identification number, images of your driver's license, government issued ID, passport, national ID card), and other similar personal information.                
                  <br/>
                  <br/>
                  <b>To participate in our communities.</b> 
                  <br />          
                  When you participate in our communities (help centers, blogs, forums, etc.), you may choose to voluntarily disclose personal information, which may be used to identify you, including personal identifiers (e.g., first name, last name, email address, phone number), customer records (e.g., company name, job title, business email address), and other similar information.                
                  <br/>
                  <br/>
                  <b>When you engage with our Services and teams.</b> 
                  <br />          
                  You may provide us with personal identifiers (e.g., first name, last name, email address, phone number) and other similar information when you fill in forms, respond to surveys, participate in promotions or research opportunities, communicate with our customer service teams, or use other features of our Services.                
                  <br/>
                  <br/>
                  <b>When you are inquiring for jobs.</b> 
                  <br />          
                  When you are inquiring for jobs at MyCarity, you may provide personal identifiers (e.g., your first name, last name, email address, date of birth, phone number, postal address), professional or employment related information (e.g., employment history, job title), education information (e.g., education level, degree, certification, school name, graduation date), and characteristics of protected classes (e.g., race, ethnicity, status, age, gender), and other similar information. In connection with an employment background screening check, you may provide other sensitive personal information (e.g., social security number, driver’s license number, passport number, tax identification number, images of your driver's license, government issued ID, passport, national ID card) and other authentication information that can be used to verify your identity and perform the background screening check.                
                  <br /><br />
                  3.2. Information Collected When Using Our Websites and Services 
                  <br/>
                  <br/>
                  <b>Device information.</b> 
                  <br />          
                  When you access our Websites or use our Services, we may collect device information that can be used to identify you or your device. This internet or electronic network information may include IP address, hardware and software operating system type and version, browser type and version, crash data, and the pages you have viewed or engaged with before or after accessing our Websites or Services.
                  <br/>
                  <br/>
                  <b>Commercial and activity information.</b> 
                  <br />          
                  We may collect commercial information about how you use our Websites or Services, such as content accessed, services you have used or interacted with, date and time of account creation, account status, log in activity, transaction history, time viewed, and links clicked.                  
                  <br/>
                  <br/>
                  <b>Geolocation data.</b> 
                  <br />          
                  When you use some of our Services, we may collect geolocation data about your precise or approximate location based on your IP address, GPS, or nearby devices (e.g., Wi-Fi access points, cell towers).                  
                  <br/>
                  <br/>
                  <b>Cookies and similar technologies.</b> 
                  <br />          
                  We leverage common industry standard capabilities to facilitate feature rich service delivery using website cookies and tracking beacons. The “Cookie Policy” Section of this Notice provides more information on how we use cookies and similar technology and your opt-out options.                  
                  <br/>
                  <br/>
                  3.3. Information Collected From Other Sources
                  <br/>
                  <br/>
                  <b>Public information.</b> 
                  <br />          
                  We may collect information about you from publicly accessible sources, such as information posted about you on the internet, news stories, and information available from governmental entities.                  
                  <br/>
                  <br/>
                  <b>Service providers and other parties.</b> 
                  <br />          
                  Service providers and other parties provide us with essential services and information we need to offer and operate our Services. These service providers and other parties include background check providers, financial service providers, marketing providers, recruiting and employment related providers, security providers, and other businesses. Personal information we obtain about you from these parties may include: criminal and credit history; information about your participation in third-party programs that provide things like insurance coverage and financial instruments; information to support marketing and advertising activities; information to review job applications and conduct employment interviews; and information about you provided by specific services, such as demographic and market segment information.                  
                  <br/>
                  <br/>
                  <b>Data from other accounts.</b> 
                  <br />          
                  We may receive information from third parties if you use a third party service that connects to MyCarity, or if you connect a third party account to your MyCarity account at any time. In these situations, you authorize that third party service to share your information with us. The specific personal information shared with us will depend on the third party’s privacy practices, agreements we have with the third party, and any choices that you may make in sharing your information, and may include your name, email address, and any other information you agree to let the third party service share with us.                  
                </li>

                <li>
                  <b>How We Use Your Information</b>
                  <br/>
                  <br/>
                  4.1. Providing Our Services
                  <br />
                  <br/>
                  We work hard to provide our users with the features, functionality, and support they need to have an efficient, intuitive, and worthwhile experience while using our Services. To do this, we use your information to:
                  <br/>
                  <br/>
                    <b>
                    - Verify your identity, authenticate your account, and maintain your account, settings, and preferences;
                    </b> <br/>
                    <b>
                    - Provide customer support and resolve questions and issues you have about our Services;                      
                    </b> <br/>
                    <b>
                    - Communicate with you about your use of our Services and notify you about upcoming changes or improvements to our Services;                     
                    </b> <br/>
                    <b>
                    - Process payments, and conduct background checks;                     
                    </b> <br/>
                    <b>
                    - Collect feedback regarding your experience;                     
                    </b> <br/>
                    <b>
                    - Understand and personalize your experience within our Services;                    
                    </b> <br/>
                    <b>
                    - Facilitate additional services and programs with third parties; and                    
                    </b> <br/>
                    <b>
                    - Operate surveys, and other promotions.                    
                    </b> <br/>
                    <br/>
                    4.2. Safeguarding Your Information and Our Services
                    <br />
                    <br/>
                    Providing you with a secure and safe experience on our Websites and Services is very important to us. To do this, we use your information to:                 
                    <br/>
                    <br/>
                      <b>
                      - Conduct proactive monitoring of suspicious activity across our systems and platforms;                    
                      </b> <br/>
                      <b>
                      - Monitor and prevent unauthorized access to your information, and account for unlawful or other fraudulent activities;                    
                      </b> <br/>
                      <b>
                      - Conduct diligence on third parties to ensure they meet our strict security and data protection standards; and                    
                      </b> <br/>
                      <b>
                      - Continuously review and improve our security and data protection practices to protect information with which we are entrusted to conduct business.                    
                      </b> <br/>
                      <br/>
                    4.3. Improving Our Services
                    <br />
                    <br/>
                    We are constantly improving our Services to better serve you. To do this, we use your information to:                    
                    <br/>
                    <br/>
                      <b>
                      - Monitor engagement and interactions and gather data analytics to improve functionalities, operations, and processes, including our security practices, algorithms, and other modeling;                      
                      </b> <br/>
                      <b>
                      - Assess the capabilities of third party partners;                      
                      </b> <br/>
                      <b>
                      - Develop new products, features, partnerships, and services;                      
                      </b> <br/>
                      <b>
                      - Perform research, testing, and analysis; and                      
                      </b> <br/>
                      <b>
                      - Prevent, find, and resolve software bugs and issues.                      
                      </b> <br/>
                      <br/>
                      4.4. Building Our Community and Marketing Our Services
                    <br />
                    <br/>
                    To build our community and engage with our users, we use your information to:                    
                    <br/>
                    <br/>
                      <b>
                      - Communicate with you about events, educational offers, and provide you with our newsletter;                      
                      </b> <br/>
                      <b>
                      - Personalize and provide content, experiences, communications, and targeted advertising to promote our Services; and                     
                      </b> <br/>
                      <b>
                      - Provide content for public forums, such as bulletin boards, blogs, or chat rooms on our Websites or within our Services.                      
                      </b> <br/>
                      <br/>
                      4.5. For Any Other Lawful, Legitimate Business Purpose
                    <br />
                    <br/>
                    We may use your information for any other lawful, legitimate business purpose including, evaluating job applications and conducting employment interviews; complying with legal obligations and other governmental demands; investigating and resolving disputes; and enforcing our Terms of Use and any other contracts.                    
                    
                </li>

                <li>
                  <b>How We Share Your Information</b> <br />
                  We do not sell your personal information to third parties for money, and we do not act as a data broker. However, we may need to share your information with service providers, third parties, and other users in order to provide our Services and perform tasks on our behalf. This section explains when and why we share your information.
                  <br/>
                  <br/>
                    5.1. Service Providers
                  <br />
                  <br/>
                  We use a variety of third-party service providers to help us provide our Services and to perform tasks on our behalf, and these third parties may require your information for such purposes. For example, service providers may help us: (a) verify your identity or authenticate your identification documents, (b) check information against public databases, (c) conduct background checks, fraud prevention, and risk assessment, (d) perform product development, maintenance, and debugging, (e) provide customer service, process payments, or insurance services, (f) automate administrative tasks; and (g) provide analytics. We have written agreements with all such third parties and they are not permitted to use your information for any purpose other than to deliver the relevant services in a professional manner, and consistent with their legal, regulatory and other obligations. Some of these disclosures may constitute “sharing” of personal information for cross-context behavioral advertising or targeted advertising purposes under certain U.S. state privacy laws.                  
                  <br/>
                  <br/>
                    5.2. Legal and Regulatory Disclosures
                  <br />
                  <br/>
                  We may disclose your information to courts, law enforcement, governmental authorities, tax authorities, or other third parties, if and to the extent we are required or permitted to do so by law or if such disclosure is reasonably necessary: (a) to comply with our legal obligations, (b) to comply with a valid legal request or to respond to claims asserted against us, (c) to respond to a valid legal request relating to a criminal investigation or alleged or suspected illegal activity or any other activity that may expose us, you, or any other of our users to legal liability, or (d) to enforce and administer our Terms of Use or other agreements with users. Such disclosures may be necessary to comply with our legal obligations, for the protection of your or another person's interests or for the purposes of keeping our Services secure, preventing harm or crime, enforcing or defending legal rights, or facilitating the collection of taxes.                
                  <br/>
                  <br/>
                    5.3. Sharing With Your Consent or Upon Your Direction
                  <br />
                  <br/>
                  With your consent or upon your direction, we may disclose your information to interact with a third party and for other purposes, such as with co-sponsors of our events or webinars.
                  <br/>
                  <br/>
                    5.4. Sharing Between Our Business Customers’ Users
                  <br />
                  <br/>
                  To help facilitate the use of our Services by our Business Customers and among their users, we may need to share certain information within that business account with other users that are authorized to access and use that business account. For example, account login information and other certain personal information may be visible by all members of the business account for purposes of confirming account permissions and users.
                  <br/>
                  <br/>
                    5.5. Public Information
                  <br />
                  <br/>
                  Some of our Services let you publish information that is visible or available to the public. For example, if you use our website services, you may elect to include certain of your information on websites available to the public at large. Similarly, if you use our service to post your listings to numerous free and paid listings sites, such listings may include your information.
                  <br/>
                  <br/>
                    5.6. Corporate Affiliates
                  <br />
                  <br/>
                  To enable or support us in providing you the Services, we may share your information within our corporate family of companies, which includes MyCarity and its wholly owned subsidiaries.
                  <br/>
                  <br/>
                    5.7. Business Transfers
                  <br />
                  <br/>
                  If we are involved in any merger, acquisition, reorganization, sale of assets, bankruptcy, or insolvency event, then we may sell, transfer, or share some or all of our assets, including your information in connection with such transaction or in contemplation of such transaction.
                </li>
                
                <li>
                  <b>Information from Children</b> <br />
                  MyCarity is not directed at children and individuals under the age of 18 are not permitted to use our Services. We do not knowingly collect information from individuals under 18 years of age. If we find out that an individual under the age of 18 has given us personal information, we will take steps to delete that information. If you believe that an individual under the age of 18 has given us personal information, please contact us at support@mycarity.us.
                </li>

                <li>
                  <b>Aggregate and De-Identified Information</b> <br />
                  As mentioned above, your information does not include information that is aggregated or de-identified. When we use or receive de-identified information, we maintain such information in a de-identified form and do not attempt to re-identify the information.                
                </li>

                <li>
                  <b>Right to Deletion and Closing your Account</b> <br />
                  You may terminate Your Account at any time. If You wish to terminate Your Account, You may simply delete Your Account in the Platform, or you may please contact MyCarity at support@mycarity.us. We will retain and use Your information as necessary to comply with our legal obligations, resolve disputes, and enforce our rights, but barring legal requirements, We will delete Your full profile within 30 days. However, if you log back in within 30 days, your deletion request will be immediately canceled and your account will be fully reinstated.                
                </li>

                <li>
                  <b>Retention of Your Information</b> <br />
                  We retain your information for no longer than necessary for the purposes for which it was collected, including for the purposes of satisfying any legal or reporting requirements, and in accordance with our legal obligations and legitimate business interests. To determine the appropriate retention period for your information, we consider the purposes for which we process the information; the nature and sensitivity of the information; the potential risk of harm from unauthorized use or disclosure of the information; and any applicable legal and reporting requirements.                
                </li>

                <li>
                  <b>Apple and Google Compliance:</b> 
                  <br />
                  <br/>
                    10.1. iOS (Apple): Our App complies with Apple's App Store guidelines, and we adhere to Apple's data handling and privacy requirements.
                  <br />
                  <br/>
                  10.2 Android (Google): For Android users, our App complies with Google Play policies, and we follow Google's guidelines for data collection, storage, and handling.
                </li>

                <li>
                  <b>Security</b> <br />
                  We are continuously implementing and updating organizational and technical security controls to help protect your information against unauthorized access, loss, destruction, or alteration. Such controls may include industry standard intrusion detection systems, encryption of data both while in transit over public networks (e.g., Internet) and while at rest (e.g., in a database or platform), and identifying and patching exploitable software and system vulnerabilities. Additionally, MyCarity personnel are trained in safe data handling practices.                
                </li>

                <li>
                  <b>External Links</b> <br />
                  Our Websites and Services may contain links to third party websites. These third party websites maintain their own policies regarding the collection and use of your information. We assume no responsibility or liability for the actions of such third parties with respect to their collection or use of your information. We encourage you to read the privacy policies of every website that you visit, including those you may reach through a link on our Websites or in our Services. We encourage you to be aware when you leave our Websites or Services and to read the privacy statements of each and every website you visit that collects your information.               
                </li>

                <li>
                  <b>State Specific Privacy Regulations</b> 
                  <br />
                  <br />
                  For California, Colorado, Connecticut, Utah, and Virginia Residents
                  <br />
                  <br />
                  Additional disclosures and information regarding our privacy practices under state-specific privacy laws, including information about your rights. These state specific terms are incorporated into this Notice by reference. (We will include a separate page for the State Specific Regulations)                
                
                </li>

                <li>
                  <b>Cookie Policy for our Website</b> 
                  <br />
                  <br />
                  What Are Cookies?
                  <br />
                  <br />
                  Cookies are text files that are downloaded to your device when you visit a website. Cookies perform many different functions and we use them for a variety of reasons, including, to learn what content is popular, improve site experience, provide efficient web page navigation, save user preferences, understand how you may interact with our Websites.                
                  <br />
                  <br />
                  Managing Cookie Preferences
                  <br />
                  <br />
                  You may manage your cookie preferences and opt out of certain cookies on our Websites. Please note that this opt out will not affect account based personalization.                
                  <br />
                  <br />
                  Browser Cookie Management
                  <br />
                  <br />
                  Certain web browsers may allow you to manage your cookie preferences through their settings. Please see documentation related to your particular browser for more information.
                  <br />
                  <br />
                  “Do Not Track” Signals
                  <br />
                  <br />
                  Do Not Track (“DNT”) signals are a way to keep your online behavior from being followed across the Internet by behavioral advertisers, analytics companies, and social media sites. As the industry lacks a comprehensive standard to implement such signals, we are unable to support DNT signals.
                </li>

                <li>
                  <b>Changes to This Notice</b> <br />
                  We may update this Notice from time to time as our Services change and privacy laws evolve. Changes to this Notice will be done online and we will indicate the “LAST UPDATED” date at the top of the Notice. If we determine, in our discretion, changes are material, we will notify you by some other method of communication, like email. When you use or interact with MyCarity, you are agreeing to the most recent terms of this Notice.                
                </li>

                <li>
                  <b>Contact Information</b> <br />
                  We hope this Notice answers all of your questions about our treatment and protection of your information, but to the extent you have further questions regarding this Notice, we invite you to (a) email us anytime at support@mycarity.us; (b) mail us at MyCarity, P.O. Box 140761 Anchorage, Alaska 99514, Attn: Data Privacy; or (c) otherwise contact us via the methods or forms described in this Notice.                
                </li>


              </ol>
              {/* <p>
                Symptoms of anxiety disorders can include feelings of panic,
                fear, and uneasiness, problems sleeping, cold or sweaty hands or
                feet, shortness of breath, heart palpitations, an inability to
                be still and calm, dry mouth, numbness or tingling in the hands
                or feet, nausea, muscle tension, and dizziness. <br /> <br />
                Treatment for these disorders do exist and often involve a
                combination of psychotherapy, behavioral therapy, and
                medication. Cognitive-behavioral therapy is a particularly
                effective form of therapy that helps people learn to recognize
                and change thought patterns that lead to troublesome feelings.
                Additionally, lifestyle changes, such as dietary adjustments,
                increased physical activity, and reduction of caffeine intake,
                can also help in managing the symptoms. <br />
                <br />
                Please consult a healthcare provider for an accurate diagnosis
                and treatment if you or someone you know is experiencing
                symptoms of an anxiety disorder. This is not an exhaustive list
                of anxiety disorders or their symptoms, so professional help
                should always be sought for mental health concerns.
              </p> */}
              {/* <blockquote
                style={{
                  backgroundImage: 'url("/images/blog/blog_details_2.jpeg")',
                }}
              >
                <p>
                  “Treatment for these disorders do exist and often involve a
                  combination of psychotherapy, behavioral therapy, and
                  medication.”
                </p>
              </blockquote> */}
              {/* <h2>
                How to Manage Anxiety Disorders: Techniques and Strategies
              </h2>
              <p>
                Anxiety disorders can be overwhelming and interfere with daily
                life, but there are ways to manage these feelings. From
                self-care strategies to professional help, below are methods to
                effectively cope with anxiety disorders.
              </p>
              <ul>
                <li>
                  <h3>1. Professional Treatment</h3>
                  If you are dealing with an anxiety disorder, seeking
                  professional help is crucial. Mental health professionals can
                  provide a diagnosis and suggest appropriate treatment options.
                  These typically include:
                </li>
                <li>
                  <h3>2. Self-Care Techniques</h3>
                  In addition to professional treatment, there are several
                  self-care strategies that you can adopt to help manage
                  anxiety: <br />
                  <br />
                  <b>Mindful meditation and relaxation techniques:</b> Deep
                  breathing exercises, progressive muscle relaxation, and
                  mindfulness meditation can reduce symptoms of anxiety by
                  promoting relaxation and reducing feelings of fear and worry.{' '}
                  <br />
                  <br />
                  <b>Healthy lifestyle:</b> Regular physical exercise, a healthy
                  diet, adequate sleep, and reducing caffeine and alcohol can
                  also help manage anxiety symptoms. <br />
                  <br />
                  <b>Maintaining a positive outlook:</b> While it may seem
                  challenging, it's beneficial to focus on positive aspects of
                  your life. Gratitude exercises or maintaining a journal to
                  record positive experiences can assist in cultivating an
                  optimistic outlook. <br />
                  <br />
                  <b>Avoiding avoidance:</b> It's common for people with anxiety
                  to avoid situations or objects that trigger their anxiety.
                  However, avoiding these triggers can reinforce the fear. Safe
                  exposure to these triggers under the guidance of a therapist
                  can help in managing and eventually reducing anxiety.
                </li>
                <li>
                  <h3>3. Utilizing Technology</h3>
                  There are numerous digital health platforms, such as
                  smartphone apps and online counseling services, that can help
                  manage anxiety disorders. These platforms can provide
                  cognitive-behavioral therapy, mindfulness training, and other
                  helpful resources.
                </li>
              </ul>
              <h2>Conclusion</h2>
              <p>
                Anxiety disorders can be daunting, but with the right
                combination of professional help and personal coping strategies,
                they can be managed effectively. It's essential to remember that
                help is available and that you're not alone in your struggle.{' '}
                <br />
                <br />
                Remember, it's crucial to reach out to a mental health
                professional if you're experiencing symptoms of an anxiety
                disorder. What works best will depend on the individual and the
                type of anxiety disorder, and it's essential to get a
                professional's guidance for effective management.
              </p> */}
            </div>
            <Spacing md="85" />
            {/* <AuthorWidget
              imgUrl="/images/blog/author.png"
              name="Author Bio"
              description="John Smith is a freelance writer and content strategist with a passion for helping businesses tell their stories. With over 10 years of experience in the industry, John has worked with a wide range of clients, from startups to Fortune 500 companies. He holds a Bachelor's degree in English from the University of California, Los Angeles (UCLA), and is an avid reader and traveler in his free time. Follow him on Twitter @johnsmithwriter for the latest updates on his work."
            />
            <Spacing md="110" />
            <CommentsWidget title="Comments" />
            <Spacing md="92" />
            <ReplyWidget title="Leave a Reply" /> */}
          </div>
          {/* <div className="col-lg-4">
            <Sidebar />
          </div> */}
        </div>
        {/* <Spacing md="135" lg="100" />
        <h2 className="mb-0 cs_fs_40 cs_medium">Related Articles</h2>
        <Spacing md="57" /> */}
        {/* <div className="row cs_gap_y_40">
          {relatedBlog?.map((item, index) => (
            <div className="col-xl-4 col-md-6" key={index}>
              <Post {...item} />
            </div>
          ))}
        </div> */}
     
      {/* <Spacing md="200" xl="150" lg="110" /> */}
      {/* <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section> */}
    </>
  );
}
